<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <custom-loading
      :loading="loading_empleados"
      color="greyTxt"
      opacity=".4"
    />
    <div
      class="ml-2 font-weight-medium text-h5 greyTxt--text pt-4 mb-4 "
    >
      Empleados
    </div>

    <v-row>
      <v-col
        cols="24"
        md="12"
      >
        <v-card
          elevation="2"
        >
          <!-- Tabla de Pedidos -->
          <generic-table
            :loading="loading_empleados"
            :headers="headers_empleados"
            :items="empleados"
            height="unset"
            :options="{
              'itemsPerPage':20
            }"

            :actions="actions_empleados"
            @openDetalle="openDetalle"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get, call } from 'vuex-pathify'
  export default {
    name: 'Empleados',

    data () {
      return {

        actions_empleados: [
          {
            icon: 'mdi-eye',
            title: 'Ver detalle',
            color: 'primary',
            emit: 'openDetalle',
          },
        ],

      }
    },

    computed: {
      empleados: get('empleados/items'),
      headers_empleados: get('empleados/headers'),
      loading_empleados: get('empleados/loading'),

    },
    created () {
      this.initialize()
    },
    methods: {
      getEmpleados: call('empleados/getData'),
      openDetalle (details) {
        this.$router.push({
          path: `/empleado/${details.legajo}`,
          query: {
            nombre: details.nombre,
            apellido: details.apellido,
            cuit: details.cuit,
          },
        })
      },
      initialize () {
        this.getEmpleados()
      },
    },
  }
</script>
